import { addError } from 'actions';
import { updateAssessmentStatus, createAssessments, updateAssessment } from 'actions/assessments';
import SimpleSelect from 'components/Inputs/SimpleSelect';
import AssessmentForm from 'containers/Forms/AssessmentForm';
import ClearBlockAssessments from 'containers/Forms/Assessment/ClearBlockAssessments';
import Modal from 'containers/Modals/Modal';
import Moment from 'moment';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import { Icon, Checkbox, Button } from '@haldor/ui';
import api from 'lib/api';
import sniffer from 'sniffer';
import DisplayName from 'components/Presentation/DisplayName';
import { Stack, Tooltip } from '@mui/material';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import moment from 'moment';

class UserPlanning extends Component {

	constructor(props) {
		super(props);

		this.state = {
			assessmentOpen: false,
			removeAssessments: false,
		}

		this.onCheckAll.bind(this);
	}

	onAssessmentSubmit = (values) => {
		return new Promise((resolve) => {
			if (values.id != null) {
				const existing = this.props.assessments.find((assessment) => assessment.id == values.id);
				this.props.updateAssessment(values, existing).then(() => {
					resolve(1);
					this.closeAssessment();
				});

				return true;
			}

			const selectedUsers = this.props.items.filter(t => t.selected);
			let data = [];

			selectedUsers.forEach((user) => {
				data.push({
					...values,
					studentId: user.id,
				});
			});

			this.props.createAssessments(data).then(() => {
				resolve(1);
				this.closeAssessment();
			});
		})
	}

	onStatusSelect = (item, value) => {

		if (item?.id == null || item?.planId == null || value?.value == null){
			return this.props.addError(this.props.translate('something-went-wrong'), 'error');
		}

		let userObj = {
			id: item.id,
			firstname: item.firstname,
			lastname: item.lastname,
			planId: item.planId,
			status: value.value
		};

		api.put("planning/student/status", userObj).then(() => {
			this.props.addError(this.props.translate('changes-saved'), 'info');
		}).catch(() => {
			this.props.addError(this.props.translate('something-went-wrong'), 'error');
		});
	}

	onAssessmentStatusSelect = (item, value) => {
		var assessment = this.props.assessments.find(t => t.studentId == item.id);
		if (assessment != null) {
			this.props.updateAssessmentStatus(assessment.id, value.value).then(() => {
				this.props.addError(this.props.translate('changes-saved'), 'info');
			});
		}
	}

	markForConversation = (student) => {
		if (this.props.onConversation != null) {
			this.props.onConversation(student);
		}
	}

	closeAssessment = () => {
		this.props.reload();
		this.setState({ assessmentOpen: false });
	}

	openAssessment = (user) => {
		if (user != null) {
			this.props.items.forEach(t => t.selected = false);
			user.selected = true;
		} else {
			this.props.items.forEach((student) => {
				const assessment = this.props.assessments.find(t => t.studentId == student.id);
				if (assessment != null) {
					student.selected = false;
				}
			})
		}

		const selectedUsers = this.props.items.filter(t => t.selected);
		if (selectedUsers.length > 0) {
			this.setState({ assessmentOpen: true });
		}
	}

	publishAllAssessments = () => {
		const { addError, translate, assessments } = this.props;
		const { items } = this.props;

		swal.fire({
			title: this.props.translate('are-you-sure'),
			text: this.props.translate('publish-all-assessments-warning'),
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: this.props.translate('No'),
			confirmButtonText: this.props.translate('yes-publish'),
		})
			.then(result => {
				if (result.value != null) {
					var requests = 0;

					items.map((user) => {
						let userAssessment = assessments.find(ass => {
							return ass.studentId == user.id;
						});

						if (userAssessment != null) {
							if (userAssessment.status != 'ASSESSMENT_PUBLISHED') {
								requests++;
								api.put(`assessments/status?assessmentId=${userAssessment.id}&status=ASSESSMENT_PUBLISHED`, null).then(() => {
									requests--;

									if (requests == 0) {
										addError(translate('changes-saved'), 'info');
										this.props.reload();
									}
								});
							}
						}
					});
				}
			});
	}

	onSelect = (user, checked) => {
		user.selected = checked;
		this.setState({});
	}

	onCheckAll = (checked) => {
		this.props.items.forEach(t => t.selected = checked);
		this.setState({});
	}

	renderStudents() {
		const { items } = this.props;

		return items.sort((a, b) => (a.lastname || "").localeCompare(b.lastname || "")).map((user, index) => {
			let assessment = null;
			if (this.props.assessments != null) {
				assessment = this.props.assessments.find(t => t.studentId == user.id);
			}
			let conversation = null;
			if (this.props.conversations != null) {
				conversation = this.props.conversations.find(conv => {
					return conv.memberships.find(member => {
						return member.membershipID == user.id;
					});
				});
			}

			let studentIsCurrentlyMemberInGroup = this.props.section.students.find((s) => s.userId == user.id);

			if (!studentIsCurrentlyMemberInGroup && this.props.section.historicMembers != null && this.props.section.historicMembers.length > 0) {
				var historicalMembership = this.props.section.historicMembers.find((m) => m.userId == user.id);
				if (historicalMembership) {
					user.endDate = historicalMembership.endDate;
				}
			}

			return (
				<tr key={index + '-id-' + user.id} className="no-hover">
					{this.props.printable ?
						null
						:
						<td>
							<Checkbox
								value={user.selected}
								onChange={checked => this.onSelect(user, checked)}
							/>
						</td>
					}

					{this.props.printable ?
						<td className="name">
							<DisplayName
								firstName={user.firstname}
								lastName={user.lastname}
								email={user.email}
								data={items}
							/>
						</td> :
						<td className="name">
							<Link to="#" style={{ cursor: 'pointer', textDecoration: 'underline', color: '#363636' }} onClick={() => this.openAssessment(user)}>
								<Stack
									direction="row"
									spacing={0.5}
									sx={{
										justifyContent: "flex-start",
										alignItems: "center",
									}}
								>
									{user.endDate ? <Tooltip title={this.props.translate('Left the group') + ' ' + moment.utc(user.endDate).local().format('YYYY-MM-DD')} placement="top" arrow>
										<ErrorRoundedIcon style={{ color: 'var(--color--blue)' }} />
									</Tooltip> : null}

									<DisplayName
										firstName={user.firstname}
										lastName={user.lastname}
										email={user.email}
										data={items}
									/>
								</Stack>

							</Link>
						</td>
					}

					{sniffer.isPhone ? null :
						<td className="status" style={{ textAlign: 'left' }}>
							<SimpleSelect
								selectedValue={user.status}
								disabled={this.props.allowStatus != null ?
									!this.props.allowStatus
									:
									this.props.printable
								}
								name="assessment-status"
								item={user}
								type="planning"
								onChange={this.onStatusSelect}
							/>
						</td>}

					{this.props.printable ?
						null
						:
						<td className="status">
							<SimpleSelect
								selectedValue={assessment?.status}
								disabled={assessment == null || this.props.printable}
								name="assessment-status"
								item={user}
								type="assessment"
								onChange={this.onAssessmentStatusSelect}
							/>
						</td>
					}

					{this.props.printable || sniffer.isPhone ?
						null
						:
						<td className="make_assessment">
							<Button
								onClick={() => this.openAssessment(user)}
								type="secondary"
							>
								{this.props.translate('assess')}
							</Button>
						</td>
					}

					{this.props.services.conversations && this.props.onConversation != null ?
						<td>
							<Link to="#" style={{ cursor: 'pointer' }} onClick={e => this.markForConversation(user)}>
								<div style={{ width: '1.75rem', position: 'relative' }}>
									{conversation != null ?
										<Icon name="Message" />
										: <Icon name="Message" bw />}

									{conversation != null && conversation.unreadMessages > 0 ?
										<div className="badge">
											<span>{conversation.unreadMessages}</span>
										</div>
										: null}
								</div>
							</Link>
						</td>
						: null}
				</tr>
			)
		});
	}

	render() {
		const selectedUsers = this.props.items.filter(t => t.selected);
		let unpublishedAssessment = null;
		let disableAssessmentsButton = true;
		let disableRemoveAssessmentButton = true;
		let assessAllButtonError = false;

		if (this.props.assessments != null) {
			unpublishedAssessment = this.props.assessments.find(ass => {
				return ass.status != 'ASSESSMENT_PUBLISHED';
			});

			disableAssessmentsButton = true;
			if (unpublishedAssessment != null) {
				disableAssessmentsButton = false;
			}
		}

		selectedUsers.forEach((user) => {
			if (user.id != null) {
				let hasAssessment = null;
				if (this.props.assessments != null) {
					hasAssessment = this.props.assessments.findIndex((ass) =>
						ass.studentId == user.id
					);
				}

				if (hasAssessment > -1) {
					assessAllButtonError = true;
					disableRemoveAssessmentButton = false;
				}
			}
		});


		let assessmentBlocks = null;
		let assessment = null;
		if (this.props.blocks != null) {
			assessmentBlocks = [...this.props.blocks].filter((block) =>
				block.type == 'Haldor.Blocks.AssessmentBlock' && block.resources.length > 0
			);
		}

		if (selectedUsers.length == 1 && this.props.assessments != null) {
			assessment = this.props.assessments.find((assessment) =>
				assessment.studentId == selectedUsers[0].id
			)
		}

		return (
			<div>
				<Modal isOpen={this.state.assessmentOpen} onClose={this.closeAssessment} title={this.props.translate('Assessment')}>
					<AssessmentForm
						onSubmit={this.onAssessmentSubmit}
						onClose={this.closeAssessment}
						initialValues={assessment}
						items={selectedUsers}
						blocks={assessmentBlocks}
						referenceId={this.props.planId}
						referenceType="Plan"
					/>
				</Modal>

				<Modal
					overridePrompt
					isOpen={this.state.removeAssessments}
					type='small'
					title={this.props.translate('Remove assessments')}
					onClose={() => this.setState({ removeAssessments: false })}
				>
					<ClearBlockAssessments
						items={selectedUsers.map(x => {
							return { assignedTo: x.id, groupName: `${x.firstname} ${x.lastname}` }
						})}
						assessments={this.props.assessments}
						section={this.props.section}
						onAbort={(reload) => {
							if (reload) this.props.reload(true);

							this.setState({ removeAssessments: false });
						}}
					/>
				</Modal>

				<table className="status-list">
					<thead>
						<tr>
							{this.props.printable ?
								null
								:
								<th style={{ padding: '1em 1.5em' }}>
									<Checkbox onChange={event => this.onCheckAll(event)} />
								</th>
							}

							<th>{this.props.translate('name')}</th>
							{sniffer.isPhone ? null :
								<th>{this.props.translate('Status')}</th>
							}
							{this.props.printable ?
								null
								:
								<th>
									{this.props.translate('assessment-status')}
								</th>
							}

							<th></th>

							{this.props.services.conversations ?
								<th></th>
								: null}
						</tr>
					</thead>

					<tbody>
						{this.renderStudents()}
					</tbody>
				</table>

				<div>
					{!this.props.printable ?
						<Button
							disabled={selectedUsers.length == 0 || assessAllButtonError}
							style={{ marginLeft: '1rem', marginBottom: '0.5em' }}
							onClick={() => this.openAssessment()}
						>
							{this.props.translate('assess-selected-students')}
						</Button>
						: null}

					{!this.props.printable ?
						<Button
							disabled={disableAssessmentsButton}
							style={{ marginLeft: '1rem', marginBottom: '0.5em' }}
							onClick={() => this.publishAllAssessments()}
						>
							{this.props.translate('publish-assessments')}
						</Button>
						: null}

					{!this.props.printable ?
						<Button style={{ marginLeft: '1rem', marginBottom: '0.5em' }}
							disabled={disableRemoveAssessmentButton || selectedUsers.length == 0}
							onClick={() => this.setState({ removeAssessments: true })}
						>
							{this.props.translate('Remove assessments')}
						</Button>
						: null}
				</div>
			</div>

		);
	}
}

function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		conversations: state.Conversation.referenceConversations,
		assessments: state.assessments.assessments,
		translate: translate(state.Languages.translations),
		section: state.planning.section,
		blocks: state.Blocks.reference,
	};
}

export default connect(mapStateToProps, {
	addError,
	updateAssessmentStatus,
	createAssessments,
	updateAssessment,
})(UserPlanning);
