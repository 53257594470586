import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateTime from '_class/DateTime';
import User from '_class/User';

import { getScheduleItem, updateLessonInList } from 'actions/schedule';
import { deleteReportedAttendance, getAttendence, getForecast } from 'actions/absence';

import Modal from 'containers/Modals/Modal';
import AttendanceHistory from 'containers/Lesson/Partials/AttendanceHistory';
import ReportHistory from 'containers/Lesson/Partials/ReportHistory';

import { Spinner } from 'UI';
import { Checkbox, Button, ButtonGroup, Table, Icon, translate } from '@haldor/ui';
import DisplayName from 'components/Presentation/DisplayName';

class UserAbsence extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedStudent: null,
			loading: true,
			submitting: false,
			reported: props.schedule.attendanceReported != null,
			values: [],
		};
	}

	setStudentValue = (student, type, valid = null, late = null) => {
		return new Promise((resolve) => {
			let values = JSON.parse(JSON.stringify(this.state.values));
			let foundExisting = this.state.values.findIndex((value) => {
				return value.user.userId == student.userId;
			});

			if (foundExisting > -1) {
				values[foundExisting].type = type;

				if (valid != null) {
					values[foundExisting].valid = valid;
				} else {
					values[foundExisting].valid = type.indexOf('VALID') == 0;
				}

				if (late != null) {
					values[foundExisting].lateDuration = late;
				} else {
					values[foundExisting].lateDuration = '';
				}
			} else {
				values.push({
					user: {
						userId: student.userId,
					},
					valid: valid != null ? valid : type.indexOf('VALID') == 0,
					type: type,
					lateDuration: late,
					absenceRecordId: null,
				});
			}

			this.setState({ values }, () => {
				resolve(1);
			});
		})
	}

	getStudentValue = (student) => {
		let foundExisting = this.state.values.findIndex((value) => {
			return value.user.userId == student.userId;
		});

		if (foundExisting > -1) {
			return this.state.values[foundExisting];
		}

		return null;
	}

	componentDidMount = () => {
		if (this.props.schedule.attendanceReported != null) {
			// This lesson has attendence
			this.props.getAttendence(this.props.schedule.id).then(() => {
				this.setValues();
			});
		} else {
			this.props.getAttendence(this.props.schedule.id);
			this.props.getForecast(this.props.schedule.id).then(() => {
				this.setValues();
			});
		}
	}

	setValues = () => {
		let items = this.props.forecast;
		let values = [];

		if (this.props.schedule.attendanceReported) {
			items = this.props.attendance;
		}

		if (items == null) {
			this.setState({ loading: false });
			return true;
		}

		let students = [];
		items.forEach((item) => {
			item.validAbsent = item.type == "VALID_ABSENCE" || item.type == "VALID_LATE";

			// if item.user is not in students, add it
			if (students.findIndex(student => student.userId == item.user.userId) == -1) {
				students.push(item.user);
			}
		});

		students.forEach(async (student) => {
			const studentItems = items.filter(item => item.user.userId == student.userId && item.status != 'Deleted');
			let value = {
				user: {
					userId: student.userId,
				},
				valid: false,
				type: 'PRESENT',
				lateDuration: '',
				absenceRecordId: null,
				id: 0,
			};

			let hasInvalid = false;
			let hasValid = false;
			let hasLate = false;
			let lateDuration = 0;

			studentItems.forEach((item) => {
				if (item.type.indexOf('VALID') == 0) {
					hasValid = true;
				}

				if (item.type.indexOf('INVALID') == 0) {
					hasInvalid = true;
				}

				if (item.type.indexOf('LATE') > -1) {
					hasLate = true;
				}

				if (item.lateDuration != null) {
					lateDuration += item.lateDuration;
				}

				value.id = item.id;

				if (item.absenceRecordId != null) {
					value.absenceRecordId = item.absenceRecordId;
				}
			});

			let type = 'PRESENT';
			if (hasValid || hasInvalid) {
				type = 'ABSENCE';
			}

			if (hasLate) {
				type = 'LATE';
			}

			if (hasInvalid && hasValid) {
				value.type = 'INVALID_' + type;
				value.valid = false;
				values.push(value);
				return true;
			}

			if (hasLate) {
				if (hasValid) {
					value.type = 'VALID_' + type;
					value.valid = true;
					value.lateDuration = lateDuration + '';
				} else {
					value.type = 'INVALID_' + type;
					value.valid = false;
					value.lateDuration = lateDuration + '';
				}

				values.push(value);
				return true;
			}

			if (hasValid) {
				value.type = 'VALID_' + type;
				value.valid = true;

				values.push(value);
				return true;
			}

			if (hasInvalid) {
				value.type = 'INVALID_' + type;
				value.valid = false;
				values.push(value);
				return true;
			}

			value.type = 'PRESENT';
			values.push(value);
		});

		this.setState({ values, loading: false });
	}

	onSubmit = () => {
		if (this.props.onSubmit) {
			this.setState({ submitting: true });
			this.props.onSubmit(this.state.values).then(() => {
				this.setState({ submitting: false });
			});
		}
	}

	getReportedBy = (item) => {
		if (item.reportedBy != null && item.reportedBy.firstName != null) {
			const reportedBy = new User(item.reportedBy);
			return reportedBy.getName()
		}

		if (item.reportedBy != null) {
			if (item.reportedBy.userId == 'TrackAttendance') {
				return this.props.translate('system');
			}

			if (item.reportedBy != '') {
				return this.props.translate('Guardian')
			}
		}

		return null;
	}

	renderUserAbsentActions = (student) => {
		let status = this.getStudentValue(student).type;
		let prefix = 'INVALID_';

		if (status.indexOf('VALID') == 0) {
			prefix = 'VALID_';
		}

		return (
			<ButtonGroup>
				{status == 'PRESENT' || status == 'VALID_PRESENT' ?
					<Button
						onClick={e => this.setStudentValue(student, 'PRESENT')}
						style={{ backgroundColor: '#4baf4d', borderColor: '#4baf4d' }}
					>
						{this.props.translate('present')}
					</Button>
					:
					<Button type="secondary" onClick={e => this.setStudentValue(student, 'PRESENT')}>
						{this.props.translate('present')}
					</Button>
				}

				{status == 'INVALID_ABSENCE' || status == 'VALID_ABSENCE' ?
					<Button
						onClick={e => this.setStudentValue(student, prefix + 'ABSENCE')}
						style={{ backgroundColor: '#df2d2e', borderColor: '#df2d2e' }}
					>
						{this.props.translate('absent-whole')}
					</Button>
					:
					<Button type="secondary" onClick={e => this.setStudentValue(student, prefix + 'ABSENCE')}>
						{this.props.translate('absent-whole')}
					</Button>
				}

				{status == 'INVALID_LATE' || status == 'VALID_LATE' ?
					<Button
						onClick={e => this.setStudentValue(student, prefix + 'LATE')}
						style={{ backgroundColor: '#f38234', borderColor: '#f38234' }}
					>
						{this.props.translate('late-partly')}
					</Button>
					:
					<Button type="secondary" onClick={e => this.setStudentValue(student, prefix + 'LATE')}>
						{this.props.translate('late-partly')}
					</Button>
				}
			</ButtonGroup>
		);
	}

	onMinutesChange = (minutes, student) => {
		minutes = minutes.replace(/[^\d]/, '');
		const userValue = this.getStudentValue(student);

		if (parseInt(minutes) !== 0) {
			if (parseInt(minutes) > this.props.schedule.length) {
				this.setStudentValue(student, userValue.type, null, this.props.schedule.length);
			} else {
				this.setStudentValue(student, userValue.type, null, parseInt(minutes));
			}
		}
	}

	onChangeValidAbsence = (student) => {
		let type = null;
		const item = this.getStudentValue(student);

		if (item.type == "VALID_ABSENCE") {
			type = "INVALID_ABSENCE";
		} else if (item.type == "VALID_LATE") {
			type = "INVALID_LATE";
		} else if (item.type == "INVALID_ABSENCE") {
			type = "VALID_ABSENCE";
		} else if (item.type == "INVALID_LATE") {
			type = "VALID_LATE";
		} else {
			type = 'PRESENT';
		}

		this.setStudentValue(student, type, type.indexOf('INVALID') == -1, item.lateDuration);
	}


	renderTable = (items) => {
		const { translate } = this.props;

		if (items == null || items.length == 0) {
			return <div className="color--meta text--center weight--bold">
				{this.props.translate('no-results')}
			</div>
		}

		let students = [];
		items.forEach((item) => {
			item.validAbsent = item.type == "VALID_ABSENCE" || item.type == "VALID_LATE";

			// if item.user is not in students, add it
			if (students.findIndex(student => student.userId == item.user.userId) == -1) {
				students.push(item.user);
			}
		});

		return (
			<Table>
				<thead>
					<tr>
						<th>{this.props.translate('name')}</th>
						<th>{this.props.translate('absent')}</th>
						<th>{this.props.translate('minutes')}</th>
						<th>{this.props.translate('mark-for-valid-absence')}</th>
						<th>{this.props.translate('Absence length')}</th>
						<th>{this.props.translate('reported-by')}</th>
						<th>{this.props.translate('reported')}</th>
					</tr>
				</thead>

				<tbody>
					{students.map((student) => {
						const user = new User(student);
						const studentItems = items.filter((item) => item.user.userId == student.userId);
						const value = this.getStudentValue(student);
						let hasHistory = false;

						studentItems.forEach((item) => {
							if (item.history != null && item.history.length > 0) {
								const history = item.history.filter((item) => item.status != 'Deleted');
								hasHistory = history.length > 0;
							}
						});
						
						return <tr key={student.userId}>
							{/* Name */}
							<td title={student.email} onClick={() => this.onStudentSelect(student)} style={{ cursor: 'pointer' }}>
								{hasHistory ?
									<Icon name="History" />
									: null}

								<DisplayName
								firstName={student.firstName}
								lastName={student.lastName}
								email={student.email}
								data={students}
								/>
							</td>

							{/* Buttons */}
							<td style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>
								{this.renderUserAbsentActions(student)}
							</td>

							{/* Minutes */}
							<td style={{ textAlign: 'left', width: 60, verticalAlign: 'top' }}>
								<div className="form-row">
									{value.type.indexOf('LATE') > -1 ?
										<input
											style={studentItems.length > 1 ? { width: 60, marginBottom: 3 } : { width: 60 }}
											type="number"
											min="0"
											max={this.props.schedule.length}
											value={value.lateDuration}
											onWheel={() => document.activeElement.blur()}
											onChange={e => this.onMinutesChange(e.target.value, student)}
										/>
										:
										<div style={{ width: 60, height: 23, marginBottom: 3 }} />
									}

									{studentItems.length > 1 ?
										studentItems.map((item, index) => {
											if (item.lateDuration == null) {
												return null;
											}

											return <div key={index} style={{ height: 35 }}>
												<input
													style={{ width: 60 }}
													type="number"
													min="0"
													max={this.props.schedule.length}
													value={item.lateDuration}
													disabled
												/>
											</div>
										})
										: null}
								</div>
							</td>

							{/* Valid absence */}
							<td>
								<div style={studentItems.length > 1 ? { marginBottom: 3 } : {}}>
									{value.type != 'PRESENT' && value.type != 'VALID_PRESENT' ?
										<Checkbox
											value={value.valid}
											onChange={() => { this.onChangeValidAbsence(student) }}
										/>
										: null}
								</div>

								{studentItems.length > 1 ?
									studentItems.map((item, index) => {
										return <div key={index} style={{ height: 35 }}>
											<Checkbox
												value={item.type.indexOf('VALID') == 0}
												style={{ float: 'right' }}
												disabled
											/>
										</div>
									})
									: null}
							</td>

							{/* Absence length */}
							<td>
								{studentItems.length == 1 ?
									studentItems[0].reported != null && studentItems[0].endTime != null ?
										<div>
											{translate('From')}: {new DateTime(studentItems[0].startTime).getHourStamp()}<br />
											{translate('To')}: {new DateTime(studentItems[0].endTime).getHourStamp()}
										</div>
										: null
									:
									<div>
										<br />

										{studentItems.map((item, index) => {
											return <div key={index}>
												{translate('From')}: {new DateTime(item.startTime).getHourStamp()}<br />
												{translate('To')}: {new DateTime(item.endTime).getHourStamp()}
												<br />
											</div>
										})}

									</div>
								}
							</td>

							{/* Reported by */}
							<td>
								{studentItems.length == 1 ?
									this.getReportedBy(studentItems[0])
									:
									<div>
										<br />

										{studentItems.map((item, index) => {
											return <div key={index}>
												{this.getReportedBy(item)}

												<br /><br />
											</div>
										})}
									</div>
								}
							</td>

							{/* Reported */}
							<td>
								{studentItems.length == 1 ?
									studentItems[0].reported != null ?
										new DateTime(studentItems[0].reported).getHourStamp()
										: null
									:
									<div>
										<br />
										{studentItems.map((item, index) => {
											return <div key={index}>
												{item.reported != null ?
													new DateTime(item.reported).getHourStamp()
													: null}

												<br /><br />
											</div>
										})}
									</div>
								}
							</td>

						</tr>
					})}
				</tbody>
			</Table>
		);
	}

	onStudentSelect = (student) => {
		let item = student;
		item.items = [];

		if (this.props.attendance != null) {
			item.items = [...this.props.attendance].filter(attendance =>
				attendance.user.userId == student.userId
			);
		}

		this.setState({ selectedStudent: item });
	}

	deleteAttendance = async () => {
		await this.setState({ loading: true });

		this.props.deleteReportedAttendance(this.props.schedule.id).then(async () => {
			this.props.schedule.attendanceReported = false;
			await this.props.getScheduleItem(this.props.schedule.id);
			await this.props.getForecast(this.props.schedule.id);
			await this.props.getAttendence(this.props.schedule.id);
			await this.props.updateLessonInList(this.props.schedule);
			this.setValues();
		});
	}

	render() {
		const { schedule } = this.props;
		const { selectedStudent } = this.state;
		const reported = schedule.attendanceReported != null;

		if (schedule == null) {
			return null;
		}

		if (this.state.loading) {
			return <Spinner center />
		}

		return (
			<div className="form">
				<Modal
					isOpen={selectedStudent != null}
					title={this.props.translate('Attendance for') + ' ' + selectedStudent?.firstName + ' ' + selectedStudent?.lastName}
					onClose={() => this.setState({ selectedStudent: null })}
					overridePrompt
				>
					<AttendanceHistory student={selectedStudent} />
				</Modal>

				{reported ?
					this.renderTable(this.props.attendance)
					:
					this.renderTable(this.props.forecast)
				}

				<div style={{ marginTop: '1.5rem', marginLeft: '1.7rem' }}>
					{reported ?
						<Button type="save" onClick={this.onSubmit}>
							{this.state.submitting ?
								<Spinner center small />
								: null}

							{reported ?
								this.props.translate('update-report')
								:
								this.props.translate('save-report')
							}
						</Button>
						: null}

					{!reported && this.props.forecast ?
						<Button type="save" onClick={this.onSubmit}>
							{this.state.submitting ?
								<Spinner center small />
								: null}

							{reported ?
								this.props.translate('update-report')
								:
								this.props.translate('save-report')
							}
						</Button>
						: null}

					{reported ?
						<Button type="secondary" style={{ marginLeft: '1rem' }} onClick={this.deleteAttendance}>
							{this.state.submitting ?
								<Spinner />
								: null}

							{this.props.translate('Remove report')}
						</Button>
						: null}
				</div>

				<ReportHistory attendance={this.props.attendance} />
			</div>
		);
	}

}

function mapStateToProps(state) {
	return {
		user: state.user.currentUser,
		attendance: state.Absence.calendarAttendence,
		translate: translate(state.Languages.translations),
		forecast: state.Absence.forecast,
		schedule: state.schedule.activeScheduleItem,
	};
};

export default connect(mapStateToProps, {
	getAttendence,
	getForecast,
	deleteReportedAttendance,
	getScheduleItem,
	updateLessonInList,
})(UserAbsence);
