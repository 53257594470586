// External Libraries
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { clearPlansFromStore } from "actions/plans";
import { useDispatch, useSelector } from 'react-redux';
import * as microsoftTeams from "@microsoft/teams-js";

// MUI Components
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// MUI Icons
import TableRowsIcon from '@mui/icons-material/TableRows';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Haldor Components
import { Button, Icon, translate as translateConstructor } from '@haldor/ui';

// Constants
import { PRESCHOOL } from 'constants/schoolTypes';

// Helpers
import { isUserStudent, isUserTeacher } from 'helpers/user';
import { getRootUrl } from 'helpers/url';
import { isMicrosoftTeams } from 'helpers/teams';

// Actions
import { setPageTitle, setPageActions } from 'actions/header';
import { getSectionByGraphId, getEducationGroups } from "actions/sections";

// Local Components
import SectionTitle from 'components/Presentation/SectionTitle';
import SectionForm from 'containers/Forms/SectionForm';
import SinglePlanForm from 'containers/Forms/PlanningForm/SinglePlanForm';
import MultiplePlanForm from 'containers/Forms/PlanningForm/MultiplePlanForm';
import MultiplePreschoolPlanForm from 'containers/Forms/PlanningForm/MultiplePreschoolPlanForm';
import Modal from 'containers/Modals/Modal';
import MyPlans from 'containers/Overview/Sections/MyPlans';
import MyPlansList from './MyPlansList';
import moment from 'moment';

const MyPlansWrapper = ({ overview = false, dateRestriction }) => {
	const [currentLayout, setCurrentLayout] = useState('list_plans_layout');
	const [multiplePlanFormModal, setMultiplePlanFormModal] = useState(false);
	const [groupSettingFormModel, setGroupSettingFormModel] = useState(false);
	const [sectionId, setSectionId] = useState(null);

	const currentUser = useSelector(state => state.user.currentUser);
	const translations = useSelector(state => state.Languages.translations);
	const group = useSelector(state => state.sections.graphSection);

	let translate = translateConstructor(translations);

	const dispatch = useDispatch();

	let isTeacher = isUserTeacher(currentUser);
	let isStudent = isUserStudent(currentUser);

	useEffect(() => {
		let actions = [];
		const layout = localStorage.getItem("plans_layoutView");

		if (layout) {
			setCurrentLayout(layout);
		}

		if (!overview) {
			dispatch(setPageTitle(translate('planning-header-overview')));
		}

		if (isTeacher && !overview) {
			actions.push({
				type: "button",
				value: translate("Create"),
				onClick: toggleMultiplePlansForm,
				buttonType: "primary",
				icon: "plus"
			});

			if (getRootUrl() == '/planning-tab/') {
				actions.push({
					type: 'icon',
					onClick: toggleGroupSettings,
					icon: 'cog_bw',
				});
			}

			dispatch(setPageActions(actions));
		}
	}, []);

	const setLayout = (newLayout) => {
		dispatch(clearPlansFromStore());
		if (newLayout) {
			localStorage.setItem("plans_layoutView", newLayout);
			setCurrentLayout(newLayout);
		}
	}

	const renderGantPlans = () => {
		return (<div>
			<MyPlans dateRestriction overview />
		</div>);
	}

	const renderListPlans = () => {
		return (<div style={{ marginTop: '1rem' }}>
			<MyPlansList />
		</div>);
	}

	const toggleGroupSettings = async () => {
		setGroupSettingFormModel(!groupSettingFormModel);
	}

	const toggleMultiplePlansForm = skipModal => {
		setMultiplePlanFormModal(!multiplePlanFormModal);
	};

	const outputForms = () => {
		var schoolType = currentUser.schools.getActiveSchool(
			currentUser.userId
		).type;

		if (sectionId != null && schoolType != PRESCHOOL) {
			return (
				<SinglePlanForm
					onAbort={toggleMultiplePlansForm}
					sectionId={sectionId}
					cloneView={false}
					editView={false}
				/>
			);
		}

		if (schoolType == PRESCHOOL) {
			return (
				<MultiplePreschoolPlanForm
					onAbort={toggleMultiplePlansForm}
				/>
			);
		}

		return <MultiplePlanForm onAbort={toggleMultiplePlansForm} />;
	};


	return (
		<div>
			<Modal
				isOpen={multiplePlanFormModal}
				onClose={() => {
					setMultiplePlanFormModal(false);
				}}
				title={translate("plan-create-button")}
			>
				{outputForms()}
			</Modal>
			{group ? <Modal isOpen={groupSettingFormModel} onClose={toggleGroupSettings} title={group.title}>
				<SectionForm
					onAbort={toggleGroupSettings}
					editView
					section={group}
				/>
			</Modal> : null}
			<Grid
				container
				direction="row"
				justifyContent="space-between"
			>

				<Grid item>
					{overview ? <SectionTitle style={{ marginBottom: '0px' }}>
						<Icon name="Plans" />
						<span>
							{translate(
								"planning-header-overview"
							)}
						</span>
						{isTeacher ? (
							<Button type="secondary" onClick={toggleMultiplePlansForm}>
								<Icon name="Plus" /> {translate("Create")}
							</Button>
						) : null}
						<Link to="plans" className="see-more">
							{translate("show-all")}
						</Link>
					</SectionTitle> : null}
				</Grid>

				<Grid item>
					<ToggleButtonGroup value={currentLayout} onChange={(event, newValue) => setLayout(newValue)} exclusive>
						<Stack spacing={0}>
							<ToggleButton value="gant_plans_layout" aria-label="laptop">
								<WidgetsIcon />
							</ToggleButton>
						</Stack>
						<Stack spacing={0}>
							<ToggleButton value="list_plans_layout" aria-label="tv">
								<TableRowsIcon />
							</ToggleButton>
						</Stack>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			{currentLayout === 'gant_plans_layout' ? renderGantPlans() : null}
			{currentLayout === 'list_plans_layout' ? renderListPlans() : null}
		</div >
	);
};

export default MyPlansWrapper;